<template>
<div >

</div>
</template>

<script>
export default {
    name: "zh_ods_nyj",
    data: function () {
        return {

        };
    },
    methods: {
        init_param: function () {

        },

    },
    beforeMount: function () {
        this.init_param();
    }
}
</script>

<style>

</style>
