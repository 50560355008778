<template>
<div></div>
</template>

<script>
export default {
    name: 'zh_test_plugin',
}
</script>

<style>

</style>
